/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { LoadingIndicator } from "components";
import "./styles.css";
import { ApiError } from "./components/ApiError";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingForm } from "./components/OnboardingForm";
import { OnboardingHeader } from "./components/OnboardingHeader";
import { WelcomeInfo } from "./components/WelcomeInfo";
import { ProcessStatus } from "./types";
import { usePublicOnboardingProcess } from "./usePublicOnboardingProcess";

export const PublicOnboardingFormView = () => {
  const [processState, setProcessState] = useState<any>(null);

  useEffect(() => {
    fetch("/processState.json")
      .then((response) => response.json())
      .then((data) => setProcessState(data))
      .catch((error) =>
        console.error("Error loading processState.json:", error)
      );
  }, []);

  const { handleSubmit, campaingCodeData } = usePublicOnboardingProcess();

  if (!processState) {
    return <LoadingIndicator center />;
  }

  const { status, formDefinition, formOptions, initialFormData } = processState;

  // Append campaignCode to initialFormData
  const updatedInitialFormData = {
    ...initialFormData,
    campaignCode: campaingCodeData?.campaignCode,
  };

  const ready =
    status === ProcessStatus.READY &&
    formDefinition &&
    initialFormData &&
    formOptions;

  const showLoading =
    status === ProcessStatus.LOADING || status === ProcessStatus.SUBMITTING;

  return (
    <>
      {ready && (
        <div className="bg-white">
          <OnboardingHeader />
          <div className="flex justify-center w-full">
            <div className="flex flex-wrap justify-center pt-3 lg:pt-10 w-full lg:max-w-[1400px]">
              <div className="lg:sticky lg:top-2 px-4 pt-2 mb-8 w-full lg:w-2/5 h-fit">
                <WelcomeInfo />
              </div>
              <div className="w-full lg:w-3/5">
                <OnboardingForm
                  initialFormData={updatedInitialFormData}
                  formOptions={formOptions}
                  formDefinition={formDefinition}
                  handleSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
          <OnboardingFooter />
        </div>
      )}
      {showLoading && (
        <div className="h-screen">
          <LoadingIndicator center />
        </div>
      )}

      {status === ProcessStatus.ERROR && (
        <ApiError resetApiError={() => window.location.reload()} />
      )}
    </>
  );
};
